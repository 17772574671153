// import here cause on every page I guess...
import 'typeface-rubik'
import 'typeface-cardo'

import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Container from './Container'

const HeaderWrapper = styled.div`
  padding: 30px 0;
`

const Brand = styled.h3`
  font-size: 20px;
  margin: 0;
  line-height: 1;
  text-align: center;
`

const Header = ({ color }) => (
  <HeaderWrapper>
    <Container>
      <Brand style={{ color }}>
        <Link
          style={{
            boxShadow: 'none',
            textDecoration: 'none',
            color: 'inherit',
          }}
          to={'/'}>
          Tipple & Nosh
        </Link>
      </Brand>
    </Container>
  </HeaderWrapper>
)

export default Header
