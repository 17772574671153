import React from 'react'

const ColoredSpan = ({ color, background, children, style }) => {
  style = style || {}
  if (color) style.color = color
  if (background) {
    style.background = background
    if (!style.padding) style.padding = '2px 5px'
  } else {
    delete style.padding
  }

  return (
    <span>
      <span style={style}>
        {children}
      </span>
      <br />
    </span>
  )
}

export default ColoredSpan
