import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'

import typography from '../utils/typography'

import Image from './Image'
import ColoredSpan from './ColoredSpan'

const PostContainer = styled((props) => (<Link {...props} />))`
  position: relative;
  display: block;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  color: white;
  top: 0;
  transition: all .2s ease;

  &:hover {
    top: -5px;
  }
`

const PostMeta = styled.div`
  position: absolute;
  bottom: 20px;
  left: 25px;
`

const PostDescription = styled.p`
  margin: 0;
  font-size: 16px;
`

const PostTitle = styled.h3`
  font-size: 18px;
  margin-top: 0;
`

const Post = ({ post }) => {
  let { title, description, colors } = post.frontmatter
  const { fluid } = post.frontmatter.image.childImageSharp
  title = title.replace(/\|/g, ' ')
  colors = colors || {}

  return (
    <PostContainer to={post.fields.slug}>
      <Image responsiveSizes={fluid} alt={title} style={{ display: 'block' }} />
      <PostMeta>
        <PostTitle style={{ marginBottom: typography.rhythm(1 / 4) }}>
          <ColoredSpan color={colors.title} background={colors.titleBackground}>
            {title}
          </ColoredSpan>
        </PostTitle>
        <PostDescription>
          <ColoredSpan color={colors.body} background={colors.bodyBackground}>
            {description}
          </ColoredSpan>
        </PostDescription>
      </PostMeta>
    </PostContainer>
  )
}

export default Post

export const query = graphql`
  fragment PostFragment on MarkdownRemark {
    fields {
      slug
    }
    frontmatter {
      title
      description
      originalPost
      tags
      notes
      colors {
        header
        title
        titleBackground
        body
        bodyBackground
      }
    }
  }

  fragment PostThumbFragment on MarkdownRemark {
    frontmatter {
      image {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            src
            srcSet
            sizes
            aspectRatio
          }
        }
      }
    }
  }

  fragment PostFullImageFragment on MarkdownRemark {
    frontmatter {
      image {
        childImageSharp {
          fluid(maxWidth: 3200, quality: 100) {
            src
            srcSet
            sizes
            aspectRatio
          }
        }
      }
    }
  }
`
