import React from 'react'

class AltBio extends React.Component {
  render () {
    return (
      <div style={this.props.style}>
        <p>
          We are Annie & Ben and we make cocktails in San Francisco. This
          website contains our original creations. {' '}
          <a href="https://instagram.com/tippleandnosh">
            Follow us on Instagram
          </a>
        </p>
      </div>
    )
  }
}

export default AltBio
