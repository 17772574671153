// https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md#media-templates
// Uses breakpoints from https://github.com/jxnblk/grid-styled#theming

import { css } from 'styled-components'
import { theme } from 'grid-styled'

const sizes = {
  tablet: theme.breakpoints[0],
  desktop: theme.breakpoints[1],
  giant: theme.breakpoints[2],
}

// iterate through the sizes and create a media template
export default Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${sizes[label]}) {
      ${css(...args)}
    }
  `
  return accumulator
}, {})
