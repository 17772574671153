import _ from 'lodash'
import React from 'react'
import { Link } from 'gatsby'
import { getTagURL } from '../utils/helpers'

const Tag = ({ tag }) => (
  <Link to={getTagURL(tag)}>{tag}</Link>
)

const Tags = ({ tags }) => {
  const sortedTags = _.sortBy(tags)
  let tagNodes = _.flatten(_.map(sortedTags, (tag, index) => (
    index === tags.length - 1
      ? <Tag key={tag} tag={tag} />
      : [<Tag key={tag} tag={tag} />, ', ']
  )))
  return (
    <span>
      {tagNodes}
    </span>
  )
}

export default Tags
