import styled from 'styled-components'
import typography from '../utils/typography'

const Sizes = {
  normal: 19,
  wide: 25,
}

const Container = styled.div`
  width: 100%;
  max-width: ${(props) => typography.rhythm(Sizes[props.size || 'normal'])};
  padding: 0 ${typography.rhythm(3 / 4)};
  margin: 0 auto;
`

export default Container
