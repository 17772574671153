import React from 'react'
import { Flex, Box } from 'grid-styled'

import Post from './Post'

const Posts = ({ posts }) => (
  <Flex flexWrap="wrap" style={{ marginBottom: 30 }}>
    {posts.map(({ node }) => (
      <Box
        key={node.fields.slug}
        width={[1, 1 / 2, 1 / 2, 1 / 2]}
        pl={[0, 10, 10, 10]}
        pr={[0, 10, 10, 10]}
        pb={20}
      >
        <Post post={node} key={node.fields.slug} />
      </Box>
    ))}
  </Flex>
)

export default Posts
