import Typography from 'typography'
import Theme from 'typography-theme-wordpress-2016'

Theme.headerFontFamily = ['rubik', 'Georgia', 'serif']
Theme.bodyFontFamily = ['cardo', 'Georgia', 'serif']
Theme.headerWeight = '600'
Theme.baseFontSize = '20px'

Theme.overrideThemeStyles = ({ rhythm }) => ({
  h1: {
    fontFamily: Theme.headerFontFamily.join(','),
  },
  'h1,h2,h3,h4,h5,h6': {
    marginTop: rhythm(1.5),
  },
  'ul,ol': {
    marginLeft: '20px',
  },
  'a.gatsby-resp-image-link': {
    boxShadow: 'none',
  },
})

// These are local. see typeface-rubik, etc in package.json
delete Theme.googleFonts

const typography = new Typography(Theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
