import React from 'react'

// gatsby-image should work, but it doesnt. It displays the image as 0px height.

const Image = ({ responsiveSizes, alt, style }) => (
  <img
    src={responsiveSizes.src}
    width="100%"
    data-src={responsiveSizes.src}
    data-srcset={responsiveSizes.srcSet}
    data-sizes={responsiveSizes.sizes}
    alt={alt}
    style={{ margin: 0, ...style }}
  />
)
export default Image
