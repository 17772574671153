import _ from 'lodash'
import React from 'react'
import ReactHelmet from 'react-helmet'
import { graphql } from 'gatsby'

import favicon from '../../static/favicon.png'
import favicon64 from '../../static/favicon64.png'

function getImageSize (image) {
  const srcs = image.srcSet.split(',')
  const srcWSize = _.filter(srcs, (s) => s.indexOf(image.src) > -1)
  if (srcWSize) {
    const width = parseInt(/([0-9]+)w/.exec(srcWSize)[1])
    const height = width / image.aspectRatio
    return { width, height }
  }
  return null
}

const Helmet = ({ siteMetadata, title, description, image, tags }) => {
  const siteTitle = siteMetadata.title
  const defaultDescription = siteMetadata.description

  description = description || defaultDescription

  let imgSrc
  let imgSize
  if (image) {
    imgSrc = siteMetadata.siteUrl + image.src
    imgSize = getImageSize(image)
  }

  return (
    <ReactHelmet
      titleTemplate={`%s · ${siteTitle}`}
      defaultTitle={siteTitle}
      title={title}>

      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />

      <meta property="og:site_name" content={siteTitle} />
      <meta name="twitter:site" content={`@${siteMetadata.twitter}`} />
      <meta name="twitter:card" content="summary_large_image" />

      <meta property="og:locale" content="en-US" />

      <meta property="og:title" content={title || siteTitle} />
      <meta property="twitter:title" content={title || siteTitle} />

      { tags && tags.length
        ? (<meta name="article:section" content={tags[0]} />)
        : null
      }

      { imgSrc ? ([
        <meta key="og:image" property="og:image" content={imgSrc} />,
        <meta key="twitter:image" name="twitter:image" content={imgSrc} />,
        <meta key="twitter:image:src" name="twitter:image:src" content={imgSrc} />,

        imgSize ? <meta key="width" property="og:image:width" content={imgSize.width} /> : null,
        imgSize ? <meta key="height" property="og:image:height" content={imgSize.height} /> : null,
      ]) : null }

      <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
      <link rel="shortcut icon" type="image/png" href={favicon64} />

    </ReactHelmet>
  )
}

export default Helmet

export const query = graphql`
  fragment HelmetFragment on Site {
    siteMetadata {
      title
      twitter
      instagram
      description
      siteUrl
    }
  }
`
